import moment from "moment";

export enum DeliveryProviders {
    postnl = 'PostNL',
    lca = 'LCA',
}

export enum SubscriptionRecurrency {
    weekly = 'weekly',
    biweekly = 'biweekly',
    monthly = 'monthly',
}

export interface DeliveryOption {
    optionIndex?:     number;
    windowStart:      Date;
    windowEnd:        Date;
    deliveryProvider: DeliveryProviders;
    extraCost:        number;
    active:           boolean;
    amountAvailable:  number;
    sameDay?:         boolean;
}

export const deliveryOptionToString: (d: DeliveryOption) => string = (d: DeliveryOption) => {
    let start = moment(d.windowStart);
    let end = moment(d.windowEnd);
    const eco = (start.format('HH:mm') === '08:00' && end.format('HH:mm') === '20:00') || (start.format('HH:mm') === '09:00' && end.format('HH:mm') === '21:00') ? ' eco-all-day' : '';
    // const extraCost = d.extraCost > 0 ? ` (+${d.extraCost.toFixed(2)}e)` : '';
    return `${start.format('dddd, D MMMM (HH:mm - ')}${end.format('HH:mm)')}${eco}`;
}

export const recurrencyToString: (r: SubscriptionRecurrency) => string = (r: SubscriptionRecurrency) => {
    switch (r) {
        case SubscriptionRecurrency.weekly: return 'Wekelijks';
        case SubscriptionRecurrency.biweekly: return '1x per 2 weken';
        case SubscriptionRecurrency.monthly: return '1x per 4 weken';
        default: return '';
    }
}
